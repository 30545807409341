@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoLight";
  src: url("./assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoThin";
  src: url("./assets/fonts/Roboto/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoFlex";
  src: url("./assets/fonts/RobotoFlex/RobotoFlex-Regular.ttf")
    format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gradient {
  background-image: url("../public/gradient_background.png");
  background-repeat: no-repeat;
  background-position: center top;
}

.landing-header-shadow {
  text-shadow: -5px 4px 4px rgba(174, 1, 255, 0.17);
}

.custom-background-shadow {
  /* background-color: green;  */
  /* border-radius: 8px; */
  /* padding: 1rem; */

  box-shadow: 0 40px 100px 80px rgba(156, 39, 176, 0.3);

  /* box-shadow: 
    0 0 50px 25px rgba(156, 39, 176, 0.3),
    0 0 60px 80px rgba(156, 39, 176, 0.2); */
}

.custom-card {
  /* padding: 15px; */
  border-radius: 30px;
  border: 1px solid #000;
  /* opacity: 0.8; */
  background: linear-gradient(
    57deg,
    #0d2e5c 22.44%,
    rgba(174, 1, 255, 0.69) 98.51%
  );
}

.gradient-bg {
  background: radial-gradient(50% 50% at 50% 50%, #ae01ff 0%, #680199 100%);
}

.text-indent {
  text-indent: 2em;
}

.card-hover-ease-shadow:hover {
  transition: box-shadow 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.8);
}

.get-started-button {
  border-radius: 71.949px;
  border: 3px solid #ae01ff;
  background: linear-gradient(90deg, #5d0088 1.1%, #5f7fc0 100%);
  transition: background 1s ease-in-out; /* Adjusted transition timing */
}

.get-started-button:hover {
  background-color: #ae01ff;
  background-image: none; /* remove gradient on hover */
  transition: background 1s ease-in-out; /* Adjusted transition timing */
  background-size: 200% 100%; /* to cover from left to right */
  background-position: left bottom; /* start from the left */
}

.add-to-chrome-btn {
  border-radius: 6px;
  border: 2px solid #9f9f9f;
  background: linear-gradient(180deg, #9c00e4 0.17%, #56007e 99.83%);
  transition: background 0.5s ease-in-out;
}

.add-to-chrome-btn:hover {
  background: linear-gradient(180deg, #04c500 0.17%, #04c500 99.83%);
}

.purple-background {
  background: radial-gradient(
    circle at 50% 50%,
    rgba(174, 1, 255, 0.69) 1%,
    black 40%
  );
}
@media (max-width: 768px) {
  .purple-background {
    background: radial-gradient(
      circle at 50% 50%,
      rgba(174, 1, 255, 0.69) 1%,
      black 65%
    );
  }
}
@media (min-width: 1570px) {
  .purple-background {
    background: radial-gradient(
      circle at 50% 50%,
      rgba(174, 1, 255, 0.69) 1%,
      black 25%
    );
  }
}

.privacy-policy-header {
  background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 195.5%;
}

.privacy-policy-p {
  /* background: radial-gradient(50% 50% at 50% 50%, #FFF 0%, #FFF 100%); */
  /* background-clip: text; */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-family: Roboto;
  font-size: 20px;
  /* font-style: normal; */
  font-weight: 200;
  line-height: 195.5%;
}

.custom-checkbox:checked {
  background: radial-gradient(50% 50% at 50% 50%, #00ff0a 0%, #737373 100%);
}

.test-css {
  border-radius: 30px;
  border: 1px solid red;
}

.input-fields:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  background-color: black !important;

  /* font-family: Roboto !important; */
  font-family: "RobotoThin";

  font-size: 20px !important;
  /* font-style: italic !important; */
  font-weight: 100 !important;

  box-shadow: 0 0 0px 1000px black inset !important;
}

/* Placeholder text color for input-fields */
.input-fields::placeholder {
  background-color: transparent !important;
  color: #9ca3af !important;
  color: rgba(255, 255, 255, 0.56);
  font-family: "RobotoThin";
  font-size: 20px;
  font-style: italic;
  font-weight: 100;
}

.my-account-card-shadow {
  filter: drop-shadow(4px 4px 4px rgba(174, 1, 255, 0.24));
}

.my-account-button-shadow {
  box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.15);
}

.login-card-width {
  width: 28%; /* Default width for large screens */
}
@media (min-width: 769px) and (max-width: 1023px) {
  .login-card-width {
    width: 45%; /* Width for medium screens */
  }
}
@media (max-width: 768px) {
  .login-card-width {
    width: 90%; /* Width for small screens */
  }
}

.sign-in-with-google {
  border-radius: 28px;
  border: 1px solid rgba(255, 255, 255, 0.33);
  background: linear-gradient(
      0deg,
      rgba(37, 37, 37, 0.74) 0%,
      rgba(37, 37, 37, 0.74) 100%
    ),
    rgba(68, 68, 68, 0.54);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 2s infinite;
}
